<template>
  <div class="modal" :class="{ 'is-active': showModal }">
    <div class="modal-background" @click.self="close_modal"></div>
    <div class="modal-card" style="width: 90vh">
      <header class="modal-card-head">
        <div class="modal-card-title">
          <div
            class="is-size-4 modal-card-title"
            v-if="$parent.currentJobDrag.parent_job == null"
          >
            <div
              v-if="this.$parent.currentJobDrag.warehouse_id"
              class="is-size-7"
            >
              {{ currentWarehouse }}
            </div>
            {{ $parent.currentMaterialDrag.Caption }}
          </div>
          <div
            class="is-size-4 modal-card-title"
            v-else-if="$parent.currentJobDrag.parent_job != null"
          >
            {{ $parent.currentTruckJobMaterialDrag.Caption }}
          </div>
        </div>
        <button class="delete" aria-label="close" @click="close_modal"></button>
      </header>
      <!-- material amount details for deliver		 -->
      <div v-if="$parent.$parent.direction">
        <div
          class="columns is-size-7 has-background-primary-dark has-text-white is-centered"
        >
          <div class="column has-text-left mt-3 p-0 pl-5">
            Delivery planning
          </div>
        </div>
        <div
          class="columns has-text-grey-light is-size-7 has-background-white is-centered"
        >
          <div class="column is-3 has-text-centered mt-1 p-0">REQUESTED</div>
          <div class="column is-3 has-text-centered mt-1 p-0">REMAINING</div>
          <div class="column is-3 has-text-centered mt-1 p-0">PLANNED</div>
          <div class="column is-3 has-text-centered mt-1 p-0">LOADED</div>
        </div>
        <div
          class="columns is-centered has-background-grey-lighter pb-3 has-text-dark"
        >
          <div class="column has-text-centered is-3 p-1">
            {{ $parent.currentMaterialDrag.QtyWas }}
          </div>
          <div class="column has-text-centered is-3 p-1">
            <span style="font-size: 12px; color: #8694a4">To Plan </span>
            <span style="margin: 10px" class="has-text-danger" title="To Plan">
              {{ original_qty - getStopAmount() - amount }}
            </span>
            |
            <span
              style="margin: 10px"
              title="To Load"
              class="has-text-danger"
              >{{
                $parent.currentMaterialDrag.QtyWas -
                $parent.currentMaterialDrag.LoadedAmount
              }}</span
            >
            <span style="font-size: 12px; color: #8694a4">To Load </span>
          </div>
          <div class="column has-text-centered is-3 p-1">
            {{ $parent.currentMaterialDrag.PlannedAmount }}
          </div>
          <div class="column has-text-centered is-3 p-1">
            {{ $parent.currentMaterialDrag.LoadedAmount }}
          </div>
        </div>
      </div>

      <!-- .................. for pickup  -->
      <div class="m-0" v-if="!$parent.$parent.direction">
        <div
          class="columns is-size-7 has-background-info has-text-white is-centered"
        >
          <div
            :class="[$parent.$parent.direction ? 'mt-0' : 'mt-3']"
            class="column has-text-left pl-5 p-0"
          >
            Pickup planning
          </div>
        </div>
        <div
          class="columns has-text-grey-light has-background-white is-centered"
          style="padding-bottom: 6px"
        >
          <div
            class="column is-2 has-text-centered"
            style="border-right: 1px solid #dee5ed"
          >
            <div class="columns m-0">
              <div class="column p-0 has-text-weight-bold is-size-6">
                ONSITE
              </div>
            </div>
            <div class="columns m-0" style="font-size: 10px">
              <div class="column p-0 pr-1">TOTAL</div>
            </div>
            <div class="columns m-0" style="font-size: 13px">
              <div class="column p-0 has-text-weight-bold" title="To load">
                {{ warehouse_total_materials.on_site }}
              </div>
            </div>
            <div class="columns m-0 ml-2" style="font-size: 12px">
              <div class="column p-0 planning" title="Planned">
                {{ warehouse_total_materials.overall_planned }}
              </div>
              <div class="column p-0 loading" title="Loaded">
                {{ warehouse_total_materials.overall_loaded }}
              </div>
              <div class="column p-0 remaining" title="Remaining">
                {{ warehouse_total_materials.on_site }}
              </div>
            </div>
          </div>
          <div
            v-if="warehouse_total_materials.transited > 0"
            :class="warehouse_total_materials.transits_to > 0 ? 'is-4' : 'is-5'"
            class="column has-text-centered"
            style="border-right: 1px solid #dee5ed"
          >
            <div class="columns m-0">
              <div class="column p-0 has-text-weight-bold is-size-6">
                TRANSIT
              </div>
            </div>
            <div class="columns m-0" style="font-size: 10px">
              <div class="column p-0 pr-1 is-2">TOTAL</div>
              <div class="column p-0 pr-1 is-5">OTHERS</div>
              <div class="column p-0 pr-1 is-5">WAREHOUSE</div>
            </div>
            <div class="columns m-0" style="font-size: 13px">
              <div class="column p-0 pr-1 is-2 has-text-weight-bold">
                {{ warehouse_total_materials.transited }}
              </div>
              <div
                class="column p-0 pr-1 is-5 has-text-weight-bold"
                title="Loaded amount"
              >
                {{
                  transit_warehouse_materials.other_transit_warehouses
                    .current_amount_loaded
                }}
                <!-- <sup style="margin-left:2px;font-size:10px!important" title="Left amount to plan"> 
									<i title="Left amount to checkin">{{transit_warehouse_materials.other_transit_warehouses.left_amount_to_checkin}}</i>
								</sup> -->
              </div>
              <div
                class="column p-0 pr-1 is-5 has-text-weight-bold"
                title="Loaded amount"
              >
                {{
                  transit_warehouse_materials.current_transit_warehouse
                    .current_amount_loaded
                }}
                <!-- <sup style="margin-left:2px;font-size:10px!important" title="Left amount to plan"> 
									<i title="Left amount to checkin">{{transit_warehouse_materials.current_transit_warehouse.left_amount_to_checkin}}</i>
								</sup> -->
              </div>
            </div>
            <div class="columns m-0">
              <div class="column p-0 is-2"></div>
              <div class="column p-0 is-5">
                <div class="columns m-0 mr-2 px-1" style="font-size: 12px">
                  <div class="column p-0 planning" title="Planned">
                    {{
                      transit_warehouse_materials.other_transit_warehouses
                        .current_amount_planned
                    }}
                  </div>
                  <div class="column p-0 loading" title="Loaded">
                    {{
                      transit_warehouse_materials.other_transit_warehouses
                        .current_amount_loaded
                    }}
                  </div>
                  <div
                    class="column p-0 remaining"
                    title="Left amount for checkin"
                  >
                    {{
                      transit_warehouse_materials.other_transit_warehouses
                        .left_amount_to_checkin
                    }}
                  </div>
                </div>
              </div>
              <div class="column p-0 is-5">
                <div class="columns m-0 mr-2 px-1" style="font-size: 12px">
                  <div class="column p-0 planning" title="Planned">
                    {{
                      transit_warehouse_materials.current_transit_warehouse
                        .current_amount_planned
                    }}
                  </div>
                  <div class="column p-0 loading" title="Loaded">
                    {{
                      transit_warehouse_materials.current_transit_warehouse
                        .current_amount_loaded
                    }}
                  </div>
                  <div
                    class="column p-0 remaining"
                    title="Left amount for checkin"
                  >
                    {{
                      transit_warehouse_materials.current_transit_warehouse
                        .left_amount_to_checkin
                    }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="column has-text-centered"
            :class="return_class()"
            :style="
              warehouse_total_materials.transits_to > 0
                ? 'border: 1px solid #dee5ed;'
                : 'border: none;'
            "
          >
            <div class="columns m-0">
              <div class="column p-0 has-text-weight-bold is-size-6">
                PROJECT
              </div>
            </div>
            <div class="columns m-0" style="font-size: 10px">
              <div class="column is-2 p-0 pr-1">TOTAL</div>
              <div class="column is-5 p-0 pr-1">OTHERS</div>
              <div class="column is-5 p-0 pr-1">WAREHOUSE</div>
              <!-- <div class="column is-2 p-0 pr-1">
								PLAN
							</div>
							<div class="column is-2 p-0 pr-1">
								LOAD
							</div> -->
            </div>
            <div
              class="columns m-0 has-text-weight-bold"
              style="font-size: 13px"
            >
              <div class="column is-2 p-0 pr-1">
                {{
                  warehouse_total_materials.other_project_warehouses +
                  warehouse_total_materials.warehouse_amount
                }}
              </div>
              <div
                class="column p-0 pr-1 is-5 has-text-weight-bold"
                title="Loaded amount"
              >
                {{
                  transit_warehouse_materials.other_project_warehouses
                    .current_amount_loaded
                }}
                <!-- <sup style="margin-left:2px;font-size:10px!important" title="Left amount to load"> 
									<i>{{transit_warehouse_materials.other_project_warehouses.left_amount_to_checkin}}</i>
								</sup> -->
              </div>
              <div
                class="column p-0 pr-1 is-5 has-text-weight-bold"
                title="Loaded amount"
              >
                {{
                  transit_warehouse_materials.current_project_warehouse
                    .current_amount_loaded
                }}
                <!-- <sup style="margin-left:2px;font-size:10px!important" title="Left amount to load"> 
									<i>{{transit_warehouse_materials.current_project_warehouse.left_amount_to_checkin}}</i>
								</sup> -->
              </div>
            </div>
            <div class="columns m-0">
              <div class="column p-0 is-2"></div>
              <div class="column p-0 is-5">
                <div class="columns m-0 mr-2 px-1" style="font-size: 12px">
                  <div class="column p-0 planning" title="Planned">
                    {{
                      transit_warehouse_materials.other_project_warehouses
                        .current_amount_planned
                    }}
                  </div>
                  <div class="column p-0 loading" title="Loaded">
                    {{
                      transit_warehouse_materials.other_project_warehouses
                        .current_amount_loaded
                    }}
                  </div>
                  <div class="column p-0 remaining" title="Remaining">
                    {{
                      transit_warehouse_materials.other_project_warehouses
                        .left_amount_to_checkin
                    }}
                  </div>
                </div>
              </div>
              <div class="column p-0 is-5">
                <div class="columns m-0 mr-2 px-1" style="font-size: 12px">
                  <div class="column p-0 planning" title="Planned">
                    {{
                      transit_warehouse_materials.current_project_warehouse
                        .current_amount_planned
                    }}
                  </div>
                  <div class="column p-0 loading" title="Loaded">
                    {{
                      transit_warehouse_materials.current_project_warehouse
                        .current_amount_loaded
                    }}
                  </div>
                  <div class="column p-0 remaining" title="Remaining">
                    {{
                      transit_warehouse_materials.current_project_warehouse
                        .left_amount_to_checkin
                    }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="warehouse_total_materials.transits_to > 0"
            class="column is-2 has-text-centered"
          >
            <div class="columns m-0">
              <div class="column p-0 has-text-weight-bold is-size-6">
                TRANSIT
              </div>
            </div>
            <div class="columns m-0" style="font-size: 10px">
              <div class="column p-0 pr-1">TO</div>
            </div>
            <div class="columns m-0" style="font-size: 13px">
              <div class="column p-0 pr-1 has-text-weight-bold">
                {{ warehouse_total_materials.transits_to }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-card-foot is-block p-2">
        <div class="create-job-modal-footer">
          <div class="">
            <p class="has-text-centered">
              <label for="" class="label">
                <!-- {{$parent.currentMaterialDrag.PlannedAmount}} -->
                <!-- {{$parent.currentMaterialDrag.QtyWas}} -->
                <!-- {{$parent.currentMaterialDrag.QtyWas - getStopAmount() - amount -  $parent.currentMaterialDrag.PlannedAmount }}
                {{amount}}
                {{original_qty}}
                -->

                <span
                  v-if="original_qty != amount && $parent.$parent.direction"
                  class="has-text-weight-bold has-text-danger"
                >
                  {{
                    $parent.currentMaterialDrag.QtyWas -
                      getStopAmount() -
                      amount -
                      $parent.currentMaterialDrag.PlannedAmount >
                    0
                      ? $parent.currentMaterialDrag.QtyWas -
                        getStopAmount() -
                        getStopAmount() -
                        amount -
                        $parent.currentMaterialDrag.PlannedAmount +
                        " not planned"
                      : "! Max: " + original_qty + " !"
                  }}
                  <!-- {{ $t("left_tj_modal") }} -->
                </span>
                <!-- <span v-else-if="((checkouts.total_amount_from_transit + checkouts.total_amount_from_warehouse) - warehouse_total_materials.planned) - amount > 0 && !$parent.$parent.direction" class="has-text-weight-bold; has-text-danger">
                                    {{((checkouts.total_amount_from_transit + checkouts.total_amount_from_warehouse) - warehouse_total_materials.planned) - amount}} {{$t('left_tj_modal')}}
                                </span>
								<span v-else-if="((checkouts.total_amount_from_transit + checkouts.total_amount_from_warehouse) - warehouse_total_materials.planned) - amount < 0 && !$parent.$parent.direction" class="has-text-weight-bold; has-text-danger">
                                	<i class="fas fa-exclamation-triangle"></i> overloaded
                                </span> -->
              </label>
            </p>
          </div>
          <div>
            <input
              class="input has-text-dark has-background-white"
              type="number"
              v-model.number="amount"
              :max="amount"
            />
          </div>
          <div v-if="$parent.$parent.direction">
            <!-- not the same warehouse -->
            <button
              v-if="warehouse_total_materials.requested != 0"
              class="button is-success is-pulled-right"
              @click.prevent="saveAmount"
            >
              {{ $t("save") }}
            </button>
          </div>
          <div v-else>
            <!-- not the same warehouse -->
            <!--v-if="this.checkouts.total_amount_from_warehouse + this.checkouts.total_amount_from_transit != 0"-->
            <button
              class="button is-success is-pulled-right"
              @click.prevent="saveAmount"
            >
              {{ $t("save") }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <button
      class="modal-close is-large"
      @click.prevent="close_modal"
      aria-label="close"
    ></button>
  </div>
</template>
<script>
import axios from "axios";
export default {
  props: ["showModal"],
  data() {
    return {
      amount: 0,
      original_qty: 0,
      already_planned: false,
      materials_checkout: [],
      checkouts: {
        total_amount_from_transit: 0,
        total_amount_from_warehouse: 0,
        total_amount_from_other_warehouse_transit: 0,
      },
      event: "",
      warehouse_total_materials: {},
    };
  },
  async created() {
    if (this.$parent.$parent.direction) {
      // delivery jobs
      console.log(this.$parent.currentJobDrag, "job drag");
      if (this.$parent.currentJobDrag.parent_job == null) {
        // debugger; //eslint-disable-line no-debugger
        console.log(this.$parent.currentMaterialDrag, "mat drag");
        this.amount =
          this.$parent.currentMaterialDrag.Qty > 0 &&
          this.$parent.currentMaterialDrag.Remains == 0
            ? this.$parent.currentMaterialDrag.QtyWas
            : this.$parent.currentMaterialDrag.QtyWas -
              this.$parent.currentMaterialDrag.PlannedAmount;

        this.original_qty = this.amount;

        this.already_planned =
          this.$parent.currentMaterialDrag.QtyWas ==
          this.$parent.currentMaterialDrag.LoadedAmount;
      } else if (this.$parent.currentJobDrag.parent_job != null) {
        this.amount = this.$parent.currentTruckJobMaterialDrag.Qty;
        this.original_qty = this.amount;
        this.amount = this.original_qty - this.getStopAmount();
      }
      if (this.already_planned) this.amount = 0;
    } else {
      console.log(
        this.$parent.currentMaterialDrag,
        this.$parent.currentTruckJobMaterialDrag
      );
      this.original_qty = this.$parent.currentMaterialDrag.LoadedAmount;
      this.already_planned =
        this.$parent.currentMaterialDrag.LoadedAmount ==
        this.$parent.currentMaterialDrag.PlannedAmountReturn;
      await axios
        .post(this.$tetris_server + "/get/truck/job/transit/materials", {
          project: this.$parent.$parent.$parent.currentEvent,
          material_id: this.$parent.currentMaterialDrag.IdST,
          warehouse_id: this.$parent.currentJobDrag.warehouse_id,
        })
        .then((res) => {
          this.materials_checkout = res.data;
          this.event = res.data[2][0].guid;
          if (this.materials_checkout[0]) {
            this.materials_checkout[0] // transit amount remaining to be checked out
              .map((m) => {
                this.checkouts.total_amount_from_transit += m.amount;
              });
          }
          if (this.materials_checkout[1]) {
            this.materials_checkout[1] // warehouse amount remaining to be checked out
              .map((m) => {
                this.checkouts.total_amount_from_warehouse += m.amount;
              });
          }
          if (this.materials_checkout[3]) {
            this.materials_checkout[3].map((t) => {
              // debugger; // eslint-disable-line no-debugger

              this.checkouts.total_amount_from_other_warehouse_transit +=
                t.amount;
            });
          }

          this.get_warehouse_total_materials();
        });
    }

    if (
      this.$parent.currentMaterialDrag.Caption == null &&
      this.$parent.currentJobDrag.parent_job == null &&
      this.$parent.currentTruckJobMaterialDrag.Caption == null &&
      this.$parent.currentJobDrag.parent_job == null
    )
      this.$parent.showModal = false;
  },

  methods: {
    return_class() {
      var col_Class = "";
      if (
        this.warehouse_total_materials.transits_to > 0 &&
        this.warehouse_total_materials.transited > 0
      ) {
        col_Class = "is-4";
      }
      if (
        this.warehouse_total_materials.transits_to > 0 &&
        this.warehouse_total_materials.transited == 0
      ) {
        col_Class = "is-8";
      }
      if (
        this.warehouse_total_materials.transits_to == 0 &&
        this.warehouse_total_materials.transited > 0
      ) {
        col_Class = "is-5";
      }
      if (
        this.warehouse_total_materials.transits_to == 0 &&
        this.warehouse_total_materials.transited == 0
      ) {
        col_Class = "is-9";
      }
      return col_Class;
    },
    get_warehouse_total_materials() {
      let mat_amount = {
        on_site: 0,
        requested: 0,
        planned: 0,
        loaded: 0,
        warehouse_amount: 0,
        other_project_warehouses: 0,
        transited: 0,
        transits_to: 0,
        overall_planned: 0,
        overall_loaded: 0,
      };

      if (this.$parent.$parent.direction) {
        // FOR DELIVERY
        this.$parent.$parent.$parent.jobs.map((j) => {
          if (j.Caption.toLowerCase().includes("- w:")) {
            if (this.$parent.$parent.direction) {
              if (j.ID == this.$parent.currentJobDrag.ej_id)
                j.materials.map((m) => {
                  if (
                    j.Stock.ID == this.$parent.currentJobDrag.warehouse_id &&
                    m.IdST == this.$parent.currentMaterialDrag.IdST
                  ) {
                    mat_amount.requested += m.QtyWas;
                    mat_amount.planned += m.PlannedAmount;
                    mat_amount.loaded += m.LoadedAmount;
                  }
                });
            }
          }
        });
      } else {
        // FOR RETURNS

        // get from the truck jobs that are already created for planning or loading
        this.$parent.$parent.project_trucks_jobs.map((tj) => {
          // it is a return job of the current warehouse
          if (
            tj.direction == 1 &&
            tj.warehouse_id == this.$parent.currentJobDrag.warehouse_id
          ) {
            tj.Qty.map((m) => {
              // is this dragged material
              if (m.IdST == this.$parent.currentMaterialDrag.IdST) {
                mat_amount.planned +=
                  tj.status_id == 0 || tj.status_id == 1 || tj.status_id == 2
                    ? m.amount
                    : 0;
                mat_amount.loaded += tj.status_id == 2 ? m.loaded_amount : 0;
              }
            });
          }

          // get amount that was brought by this warehouse (deliveries)
          if (tj.direction == 0) {
            if (tj.warehouse_id == this.$parent.currentJobDrag.warehouse_id) {
              tj.Qty.map((m) => {
                // is this dragged material
                if (m.IdST == this.$parent.currentMaterialDrag.IdST) {
                  // warehouse amount
                  mat_amount.warehouse_amount +=
                    tj.status_id == 2 ? m.amount : 0;
                }
              });
            }
            //its a different warehouse from what we are dragging and not a transit job
            if (
              tj.warehouse_id != 0 &&
              tj.warehouse_id != this.$parent.currentJobDrag.warehouse_id
            ) {
              tj.Qty.map((m) => {
                // is this dragged material
                if (m.IdST == this.$parent.currentMaterialDrag.IdST) {
                  // warehouse amount
                  mat_amount.other_project_warehouses +=
                    tj.status_id == 2 ? m.amount : 0;
                }
              });
            }
          }

          // get amount total that was brought by transits (deliveries)
          if (
            tj.direction == 0 &&
            tj.warehouse_id == 0 &&
            tj.connected_project_id
          ) {
            tj.Qty.map((m) => {
              // is this dragged material
              if (m.IdST == this.$parent.currentMaterialDrag.IdST) {
                // the job brought materials from the transit project
                if (tj.project_id == this.event)
                  mat_amount.transited += tj.status_id == 2 ? m.amount : 0;
                // the job sends materials to the transit project
                if (tj.connected_project_id == this.event)
                  mat_amount.transits_to += tj.status_id == 2 ? m.amount : 0;
              }
            });
          }

          // get amount total that was brought by all (deliveries)
          if (tj.direction == 0) {
            tj.Qty.map((m) => {
              // is this dragged material
              if (m.IdST == this.$parent.currentMaterialDrag.IdST) {
                if (tj.connected_project_id) {
                  //is a transit job
                  // console.log(tj.project_id, tj.connected_project_id, this.event, m, tj.status_id, 'TRANSIT JOBS')
                  if (tj.connected_project_id == this.event) {
                    mat_amount.requested -= tj.status_id == 2 ? m.amount : 0;
                  } else {
                    mat_amount.requested += tj.status_id == 2 ? m.amount : 0;
                  }
                } else {
                  //is a normal truck job
                  // console.log(tj.project_id, tj.connected_project_id, this.event, m, tj.status_id, 'TRUCK JOBS')s
                  mat_amount.requested += tj.status_id == 2 ? m.amount : 0;
                }
              }
            });
          }

          //get all amount for returns of all warehouses
          if (tj.direction == 1) {
            tj.Qty.map((m) => {
              // is this dragged material
              if (m.IdST == this.$parent.currentMaterialDrag.IdST) {
                //we havent confirmed the planning yet
                mat_amount.overall_planned +=
                  tj.status_id == 0 || tj.status_id == 1 || tj.status_id == 2
                    ? m.amount
                    : 0;
                mat_amount.overall_loaded += tj.status_id == 2 ? m.amount : 0;
              }
            });
          }
        });

        //material on site
        mat_amount.on_site = mat_amount.requested - mat_amount.overall_loaded;

        // if(mat_amount.warehouse_amount > mat_amount.requested){
        // 	/// mat_amount.warehouse_amount = mat_amount.requested
        // 	this.amount = mat_amount.requested - mat_amount.planned
        // 	// mat_amount.warehouse_amount = this.amount
        // }
        // else {
        // 	var a = (this.checkouts.total_amount_from_warehouse + this.checkouts.total_amount_from_transit) - mat_amount.planned
        // 	this.amount = a > 0 ? a : 0
        // }
      }

      this.warehouse_total_materials = mat_amount;
    },
    getStopAmount() {
      let a = 0;
      this.$parent.$parent.selected_warehouse_jobs.map((wj) => {
        wj.truck_jobs.map((tj) => {
          if (tj.guid == this.$parent.currentJobDrag.parent_job) {
            tj.stops.map((s) => {
              s.Qty.map((amount) => {
                if (
                  amount.material_id ==
                  this.$parent.currentTruckJobMaterialDrag.material_id
                )
                  a = a + amount.Qty;
              });
            });
          }
        });
      });
      return a;
    },
    close_modal() {
      this.$parent.showModal = false;
      this.$parent.onAddDrag();
    },
    saveAmount() {
      console.log("yes", this.$parent.currentMaterialDrag.Qty);
      this.$parent.modal_amount_choosed = this.amount;
      this.close_modal();
      this.$toast(this.$t("material_added_to_truck"));
    },
  },

  computed: {
    transit_warehouse_materials() {
      var amount = {
        current_transit_warehouse: {
          current_amount_planned: 0,
          current_amount_loaded: 0,
          left_amount_to_checkin: 0,
          to_add_to_project: 0,
        },
        other_transit_warehouses: {
          current_amount_planned: 0,
          current_amount_loaded: 0,
          left_amount_to_checkin: 0,
          to_add_to_project: 0,
        },
        current_project_warehouse: {
          current_amount_planned: 0,
          current_amount_loaded: 0,
          left_amount_to_checkin: 0,
          to_add_to_transit: 0,
        },
        other_project_warehouses: {
          current_amount_planned: 0,
          current_amount_loaded: 0,
          left_amount_to_checkin: 0,
          to_add_to_transit: 0,
        },
      };

      var transit_overall_planned = 0;
      var project_overall_planned = 0;

      this.$parent.$parent.project_trucks_jobs.map((tj) => {
        //if it is a returning truck job
        if (tj.direction == 1) {
          tj.Qty.map((m) => {
            //if it is a transit warehouse job
            if (tj.details.transit == 2) {
              //get amount totall that was loaded for transit
              //TODO remove status 0 and 1
              if (m.IdST == this.$parent.currentMaterialDrag.IdST) {
                if (
                  tj.status_id == 0 ||
                  tj.status_id == 1 ||
                  tj.status_id == 2
                ) {
                  transit_overall_planned += m.amount;
                }
              }
              //if the warehouse id corresponds to the same one we are dragging it into
              if (tj.warehouse_id == this.$parent.currentJobDrag.warehouse_id) {
                //the amount that is loaded in the transit warehouses is less than required
                if (
                  this.warehouse_total_materials.transited >=
                  transit_overall_planned
                ) {
                  //if it has already materials in it and it matches the material we are dragging
                  if (m.IdST == this.$parent.currentMaterialDrag.IdST) {
                    //TODO remove status == 0
                    amount.current_transit_warehouse.current_amount_planned +=
                      tj.status_id == 0 ||
                      tj.status_id == 1 ||
                      tj.status_id == 2
                        ? m.amount
                        : 0;
                    //loaded amount for current transit warehouse
                    amount.current_transit_warehouse.current_amount_loaded +=
                      tj.status_id == 2 ? m.amount : 0;
                  }
                }

                //the amount that is loaded in the transit warehouses is bigger than required
                if (
                  this.warehouse_total_materials.transited <
                  transit_overall_planned
                ) {
                  //its the same material
                  if (m.IdST == this.$parent.currentMaterialDrag.IdST) {
                    //if this warehouse has brought this material directly to this project
                    if (this.warehouse_total_materials.warehouse_amount > 0) {
                      //the extra amount that is loaded
                      amount.current_transit_warehouse.to_add_to_project =
                        transit_overall_planned -
                        this.warehouse_total_materials.transited;

                      //if the loaded amount is less than what was checked out from this transit warehouse
                      if (
                        this.warehouse_total_materials.transited >= m.amount
                      ) {
                        //TODO remove status == 0
                        amount.current_transit_warehouse.current_amount_planned +=
                          tj.status_id == 0 ||
                          tj.status_id == 1 ||
                          tj.status_id == 2
                            ? m.amount
                            : 0;
                        //loaded amount for current transit warehouse
                        amount.current_transit_warehouse.current_amount_loaded +=
                          tj.status_id == 2 ? m.amount : 0;
                      } else {
                        //TODO remove status == 0
                        amount.current_transit_warehouse.current_amount_planned +=
                          tj.status_id == 0 ||
                          tj.status_id == 1 ||
                          tj.status_id == 2
                            ? this.warehouse_total_materials.transited
                            : 0;
                        //loaded amount for current transit warehouse
                        amount.current_transit_warehouse.current_amount_loaded +=
                          tj.status_id == 2
                            ? this.warehouse_total_materials.transited
                            : 0;
                      }
                    } else {
                      //there is no direct warehouse for this project
                      amount.current_transit_warehouse.to_add_to_project = 0;
                      //TODO remove status == 0
                      amount.current_transit_warehouse.current_amount_planned +=
                        tj.status_id == 0 ||
                        tj.status_id == 1 ||
                        tj.status_id == 2
                          ? m.amount
                          : 0;
                      //loaded amount for current transit warehouse
                      amount.current_transit_warehouse.current_amount_loaded +=
                        tj.status_id == 2 ? m.amount : 0;
                    }
                  }
                }
              }
              //the other transit warehouse
              if (tj.warehouse_id != this.$parent.currentJobDrag.warehouse_id) {
                //the amount that is loaded in the transit warehouses is less than required
                if (
                  this.warehouse_total_materials.transited >=
                  transit_overall_planned
                ) {
                  //if it has already materials in it and it matches the material we are dragging
                  if (m.IdST == this.$parent.currentMaterialDrag.IdST) {
                    //TODO remove status == 0
                    amount.other_transit_warehouses.current_amount_planned +=
                      tj.status_id == 0 ||
                      tj.status_id == 1 ||
                      tj.status_id == 2
                        ? m.amount
                        : 0;
                    //loaded amount for current transit warehouse
                    amount.other_transit_warehouses.current_amount_loaded +=
                      tj.status_id == 2 ? m.amount : 0;
                  }
                }
                console.log(
                  "transiteeed",
                  this.warehouse_total_materials.transited
                );
                //the amount that is loaded in the transit warehouses is bigger than required
                if (
                  this.warehouse_total_materials.transited <
                  transit_overall_planned
                ) {
                  //its the same material
                  if (m.IdST == this.$parent.currentMaterialDrag.IdST) {
                    //if this warehouse has brought this material directly to this project
                    if (
                      this.warehouse_total_materials.other_project_warehouses >
                      0
                    ) {
                      //the extra amount that is loaded
                      amount.other_transit_warehouses.to_add_to_project =
                        transit_overall_planned -
                        this.warehouse_total_materials.transited;

                      //if the loaded amount is less than what was checked out from this transit warehouse
                      if (
                        this.warehouse_total_materials.transited >= m.amount
                      ) {
                        //TODO remove status == 0
                        amount.other_transit_warehouses.current_amount_planned +=
                          tj.status_id == 0 ||
                          tj.status_id == 1 ||
                          tj.status_id == 2
                            ? m.amount
                            : 0;
                        //loaded amount for current transit warehouse
                        amount.other_transit_warehouses.current_amount_loaded +=
                          tj.status_id == 2 ? m.amount : 0;
                      } else {
                        //TODO remove status == 0
                        amount.other_transit_warehouses.current_amount_planned +=
                          tj.status_id == 0 ||
                          tj.status_id == 1 ||
                          tj.status_id == 2
                            ? this.warehouse_total_materials.transited
                            : 0;
                        //loaded amount for current transit warehouse
                        amount.other_transit_warehouses.current_amount_loaded +=
                          tj.status_id == 2
                            ? this.warehouse_total_materials.transited
                            : 0;
                      }
                    } else {
                      //there is no direct warehouse for this project
                      amount.other_transit_warehouses.to_add_to_project = 0;
                      //TODO remove status == 0
                      amount.other_transit_warehouses.current_amount_planned +=
                        tj.status_id == 0 ||
                        tj.status_id == 1 ||
                        tj.status_id == 2
                          ? m.amount
                          : 0;
                      //loaded amount for current transit warehouse
                      amount.other_transit_warehouses.current_amount_loaded +=
                        tj.status_id == 2 ? m.amount : 0;
                    }
                  }
                }

                console.log(
                  "m",
                  this.checkouts.total_amount_from_other_warehouse_transit,
                  m,
                  this.warehouse_total_materials.transited,
                  transit_overall_planned
                );
              }
            }
            //it is a direct project warehouse
            if (tj.details.transit == 0) {
              //get amount totall that was loaded for project
              //TODO remove status 0 and 1
              if (m.IdST == this.$parent.currentMaterialDrag.IdST) {
                if (
                  tj.status_id == 0 ||
                  tj.status_id == 1 ||
                  tj.status_id == 2
                ) {
                  project_overall_planned += m.amount;
                }
              }

              //if the warehouse id corresponds to the same one we are dragging it into
              if (tj.warehouse_id == this.$parent.currentJobDrag.warehouse_id) {
                //the amount that is loaded in the project warehouses is less than required
                if (
                  project_overall_planned <=
                  this.warehouse_total_materials.warehouse_amount
                ) {
                  //if it has already materials in it and it matches the material we are dragging
                  if (m.IdST == this.$parent.currentMaterialDrag.IdST) {
                    //TODO remove status == 0
                    amount.current_project_warehouse.current_amount_planned +=
                      tj.status_id == 0 ||
                      tj.status_id == 1 ||
                      tj.status_id == 2
                        ? m.amount
                        : 0;
                    //loaded amount for current transit warehouse
                    amount.current_project_warehouse.current_amount_loaded +=
                      tj.status_id == 2 ? m.amount : 0;
                  }
                }
                //the amount that is loaded in the project warehouses is bigger than required
                if (
                  project_overall_planned >
                  this.warehouse_total_materials.warehouse_amount
                ) {
                  if (m.IdST == this.$parent.currentMaterialDrag.IdST) {
                    //a transit warehouse brought this material too
                    if (this.checkouts.total_amount_from_transit > 0) {
                      // //the extra amount that is loaded
                      amount.current_project_warehouse.to_add_to_transit =
                        project_overall_planned -
                        this.warehouse_total_materials.warehouse_amount;

                      //if the loaded amount is less than what was checked out from this project warehouse
                      if (
                        m.amount <=
                        this.warehouse_total_materials.warehouse_amount
                      ) {
                        console.log(
                          "testi",
                          this.warehouse_total_materials.warehouse_amount
                        );
                        //TODO remove status == 0
                        amount.current_project_warehouse.current_amount_planned +=
                          tj.status_id == 0 ||
                          tj.status_id == 1 ||
                          tj.status_id == 2
                            ? m.amount
                            : 0;
                        //loaded amount for current transit warehouse
                        amount.current_project_warehouse.current_amount_loaded +=
                          tj.status_id == 2 ? m.amount : 0;
                      } else {
                        //TODO remove status == 0
                        amount.current_project_warehouse.current_amount_planned +=
                          tj.status_id == 0 ||
                          tj.status_id == 1 ||
                          tj.status_id == 2
                            ? this.warehouse_total_materials.warehouse_amount
                            : 0;
                        //loaded amount for current transit warehouse
                        amount.current_project_warehouse.current_amount_loaded +=
                          tj.status_id == 2
                            ? this.warehouse_total_materials.warehouse_amount
                            : 0;
                      }
                    } else {
                      //there is no transit warehouse for this project
                      amount.current_project_warehouse.to_add_to_transit = 0;
                      //TODO remove status == 0
                      amount.current_project_warehouse.current_amount_planned +=
                        tj.status_id == 0 ||
                        tj.status_id == 1 ||
                        tj.status_id == 2
                          ? m.amount
                          : 0;
                      //loaded amount for current transit warehouse
                      amount.current_project_warehouse.current_amount_loaded +=
                        tj.status_id == 2 ? m.amount : 0;
                    }
                  }
                }
              }
              if (tj.warehouse_id != this.$parent.currentJobDrag.warehouse_id) {
                //the amount that is loaded in the other project warehouses is less than required
                if (
                  project_overall_planned <=
                  this.warehouse_total_materials.other_project_warehouses
                ) {
                  //if it has already materials in it and it matches the material we are dragging
                  if (m.IdST == this.$parent.currentMaterialDrag.IdST) {
                    console.log(
                      "other prject warehouse",
                      tj,
                      this.checkouts.total_amount_from_other_warehouse_transit
                    );
                    //TODO remove status == 0
                    amount.other_project_warehouses.current_amount_planned +=
                      tj.status_id == 0 ||
                      tj.status_id == 1 ||
                      tj.status_id == 2
                        ? m.amount
                        : 0;
                    //loaded amount for current transit warehouse
                    amount.other_project_warehouses.current_amount_loaded +=
                      tj.status_id == 2 ? m.amount : 0;
                  }
                }
                //the amount that is loaded in the other project warehouses is bigger than required
                if (
                  project_overall_planned >
                  this.warehouse_total_materials.other_project_warehouses
                ) {
                  if (m.IdST == this.$parent.currentMaterialDrag.IdST) {
                    //a transit warehouse brought this material too
                    if (
                      this.checkouts.total_amount_from_other_warehouse_transit >
                      0
                    ) {
                      //if the loaded amount is less than what was checked out from this project warehouses
                      if (
                        m.amount <=
                        this.warehouse_total_materials.other_project_warehouses
                      ) {
                        //TODO remove status == 0
                        amount.other_project_warehouses.current_amount_planned +=
                          tj.status_id == 0 ||
                          tj.status_id == 1 ||
                          tj.status_id == 2
                            ? m.amount
                            : 0;
                        //loaded amount for current transit warehouse
                        amount.other_project_warehouses.current_amount_loaded +=
                          tj.status_id == 2 ? m.amount : 0;
                      } else {
                        //TODO remove status == 0
                        amount.other_project_warehouses.current_amount_planned +=
                          tj.status_id == 0 ||
                          tj.status_id == 1 ||
                          tj.status_id == 2
                            ? this.warehouse_total_materials
                                .other_project_warehouses
                            : 0;
                        //loaded amount for current transit warehouse
                        amount.other_project_warehouses.current_amount_loaded +=
                          tj.status_id == 2
                            ? this.warehouse_total_materials
                                .other_project_warehouses
                            : 0;
                      }
                      //the extra amount that is loaded
                      amount.other_project_warehouses.to_add_to_transit =
                        project_overall_planned -
                        this.warehouse_total_materials.other_project_warehouses;
                    } else {
                      //there is no transit warehouse for this project
                      amount.other_project_warehouses.to_add_to_transit = 0;
                      //TODO remove status == 0
                      amount.other_project_warehouses.current_amount_planned +=
                        tj.status_id == 0 ||
                        tj.status_id == 1 ||
                        tj.status_id == 2
                          ? m.amount
                          : 0;
                      //loaded amount for other transit warehouse
                      amount.other_project_warehouses.current_amount_loaded +=
                        tj.status_id == 2 ? m.amount : 0;
                    }
                  }
                  console.log("loaded more than required");
                }
              }
            }
          });

          //extra materials checked in from the current project warehouse
          if (
            amount.current_project_warehouse.to_add_to_transit > 0 &&
            tj.Qty.length > 0 &&
            tj.details.transit == 0 &&
            tj.warehouse_id == this.$parent.currentJobDrag.warehouse_id
          ) {
            //TODO remove status 0
            if (tj.status_id == 0 || tj.status_id == 1 || tj.status_id == 2) {
              amount.current_transit_warehouse.current_amount_planned =
                amount.current_transit_warehouse.current_amount_planned +
                amount.current_project_warehouse.to_add_to_transit;
            }
            if (tj.status_id == 2) {
              amount.current_transit_warehouse.current_amount_loaded =
                amount.current_transit_warehouse.current_amount_loaded +
                amount.current_project_warehouse.to_add_to_transit;
            }
          }

          //extra materials checked in from the other project warehouse
          if (
            amount.other_project_warehouses.to_add_to_transit > 0 &&
            tj.Qty.length > 0 &&
            tj.details.transit == 0 &&
            tj.warehouse_id != this.$parent.currentJobDrag.warehouse_id
          ) {
            //TODO remove status 0
            if (tj.status_id == 0 || tj.status_id == 1 || tj.status_id == 2) {
              amount.other_transit_warehouses.current_amount_planned =
                amount.other_transit_warehouses.current_amount_planned +
                amount.other_project_warehouses.to_add_to_transit;
            }
            if (tj.status_id == 2) {
              amount.other_transit_warehouses.current_amount_loaded =
                amount.other_transit_warehouses.current_amount_loaded +
                amount.other_project_warehouses.to_add_to_transit;
            }
          }

          //extra materials checked in from the transit
          if (
            amount.current_transit_warehouse.to_add_to_project > 0 &&
            tj.Qty.length > 0 &&
            tj.details.transit == 2 &&
            tj.warehouse_id == this.$parent.currentJobDrag.warehouse_id
          ) {
            console.log(
              "extra for project",
              tj.details.transit,
              tj.warehouse_id,
              this.$parent.currentJobDrag.warehouse_id,
              tj
            );
            //TODO remove status 0
            if (tj.status_id == 0 || tj.status_id == 1 || tj.status_id == 2) {
              amount.current_project_warehouse.current_amount_planned =
                amount.current_project_warehouse.current_amount_planned +
                amount.current_transit_warehouse.to_add_to_project;
            }
            if (tj.status_id == 2) {
              amount.current_project_warehouse.current_amount_loaded =
                amount.current_project_warehouse.current_amount_loaded +
                amount.current_transit_warehouse.to_add_to_project;
            }
          }
          //extra materials checked in from the other transit warehouse
          if (
            amount.other_transit_warehouses.to_add_to_project > 0 &&
            tj.Qty.length > 0 &&
            tj.details.transit == 2 &&
            tj.warehouse_id != this.$parent.currentJobDrag.warehouse_id
          ) {
            if (tj.status_id == 0 || tj.status_id == 1 || tj.status_id == 2) {
              amount.other_project_warehouses.current_amount_planned =
                amount.other_project_warehouses.current_amount_planned +
                amount.other_transit_warehouses.to_add_to_project;
            }
            if (tj.status_id == 2) {
              amount.other_project_warehouses.current_amount_loaded =
                amount.other_project_warehouses.current_amount_loaded +
                amount.other_transit_warehouses.to_add_to_project;
            }
          }
        }

        //left amount to load for current transit warehouse
        //TODO replace it with current_amount_loaded
        amount.current_transit_warehouse.left_amount_to_checkin =
          this.checkouts.total_amount_from_transit; //- amount.current_transit_warehouse.current_amount_planned  //amount.current_transit_warehouse.current_amount_loaded

        //left amount to load for other transit warehouses
        //TODO replace it with current_amount_loaded
        amount.other_transit_warehouses.left_amount_to_checkin =
          this.checkouts.total_amount_from_other_warehouse_transit; //- amount.other_transit_warehouses.current_amount_planned

        //left amount to load for project warehouses
        amount.other_project_warehouses.left_amount_to_checkin =
          this.warehouse_total_materials.other_project_warehouses -
          amount.other_project_warehouses.current_amount_planned;
        amount.current_project_warehouse.left_amount_to_checkin =
          this.warehouse_total_materials.warehouse_amount -
          amount.current_project_warehouse.current_amount_planned;
      });
      console.log(amount.other_transit_warehouses, "other transit");
      console.log(this.checkouts, "total amount from other warehouse transit");
      //   console.log("amount", amount);

      return amount;
    },

    currentWarehouse() {
      return this.$parent.$parent.$parent.warehouses.find(
        (w) => w.Id == this.$parent.currentJobDrag.warehouse_id
      ).Caption;
    },
  },
};
</script>
<style scoped>
.planning {
  background: #91d6a1;
  color: white;
  padding: 0 4px 0 4px;
  font-weight: 700;
}

.loading {
  background: #70d587;
  color: white;
  padding: 0 4px 0 4px;
  font-weight: 700;
}

.remaining {
  background: #16ba3b;
  color: white;
  padding: 0 4px 0 4px;
  font-weight: 700;
}

.modal-card {
  max-width: calc(100vh - 170px);
  /* max-height: calc(100vh - 160px); */
  max-height: 95vh;
}

.job-type-wrap {
  display: flex;
  justify-content: space-around;
}

.job-type-wrap > * {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.qty-info-head > span {
  /* border-bottom:solid 1px #000; */
}

.ware-qty-table tbody tr th {
  border-bottom: solid 1px #dbdbdb;
}

.create-job-modal-footer {
  display: flex;
  align-items: center;
}

.create-job-modal-footer > * {
  flex-grow: 1;
  flex-basis: 0;
}
</style>
